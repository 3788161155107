import React, { useState } from "react";
import { RatingFillIcon } from "../../../../SvgIcons/Icons";

const Grocery = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const groceryData = data || [];
  const groceryDataToShow = showMore ? groceryData : groceryData.slice(0, 6);

  return (
    <>
      <div className="row mx-0">
        {groceryDataToShow?.map((item, i) => {
          return (
            <div
              className="col-md-6 col-12 mbl_Xpadding0 px-2 explore_propertyCard d-flex mt-4"
              key={i}
            >
              <div>
                <img src='https://images.pexels.com/photos/264636/pexels-photo-264636.jpeg' loading="lazy" alt="ShopImg" />
              </div>
              <div className="ps-2 w-100">
                <h5 className="PropertyDetails_Mbl_f18">{item?.name}</h5>
                <h6 className="mb-1 PropertyDetails_Mbl_f14">{`Grocerry | Fruits | Veggies`}</h6>
                <h6>
                  <span>1100m</span>
                  {` | 14 min walk`}
                </h6>
              </div>
              <div className="Explore_prop_rating">
                <RatingFillIcon /> <span>5</span>
              </div>
            </div>
          );
        })}
      </div>

      {groceryData?.length > 6 && !showMore ? (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      ) : null}
    </>
  );
};

export default Grocery;
