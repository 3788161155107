import {
  CardContent,
  CardMedia,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BathtubIcon from "@mui/icons-material/Bathtub";
import { IoBed } from "react-icons/io5";
import { FaVectorSquare, FaParking } from "react-icons/fa";
import baseURL from "../../../config";
import axios from "axios";
import "./CardMain.css";
import { useSelector } from "react-redux";

export default function CardMiddleSection({
  item,
  showListingOwnerName,
  listingDateHide,
}) {
  const favouriteData = useSelector((state) => state.heartDataSlice);
  const [bedValue, setBedValue] = useState();
  // eslint-disable-next-line
  const [cardIde, setCardIde] = useState([]);
  useEffect(() => {
    const cardIds = favouriteData?.favItems?.map((item) => item.id);
    setCardIde(cardIds);
  }, [favouriteData]);
  const [, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  function formatClosingDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const handleTotalClicked = async (item) => {
    try {
      const response = await fetch("https://api64.ipify.org/?format=json");

      if (!response.ok) {
        console.error("Network response was not ok");
      }
      const data = await response.json();
      await axios.post(`${baseURL}/api/data/AddAnalytic`, {
        Id: 0,
        AssignmentId: item.id,
        IpAddress: data.ip,
        Lat: 0,
        Long: 0,
        UserId: item.userId ? item.userId : null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const nowAssignmentPrice =
    item?.assignmentPrices?.length > 0
      ? item?.assignmentPrices[item?.assignmentPrices?.length - 1].prices
      : item?.assignmentPrice;
  const WasSqftPrice = Math.ceil(item?.assignmentPrice / item?.interiorSize);
  const NowSqftPrice = Math.ceil(nowAssignmentPrice / item?.interiorSize);
  useEffect(() => {
    if (item?.bed === "0B") {
      setBedValue("Studio");
    } else if (item?.bed === "1B") {
      setBedValue("1 Bed");
    } else if (item?.bed === "1B+D") {
      setBedValue("1 Bed + D");
    } else if (item?.bed === "2B") {
      setBedValue("2 Bed");
    } else if (item?.bed === "2B+D") {
      setBedValue("2 Bed + D");
    } else if (item?.bed === "3B") {
      setBedValue("3 Bed");
    } else if (item?.bed === "3B+D") {
      setBedValue("3 Bed+D");
    } else if (item?.bed === "4B") {
      setBedValue("4 Bed");
    } else if (item?.bed === "4B+D") {
      setBedValue("4 Bed+D");
    } else if (item?.bed === "5B") {
      setBedValue("5 Bed");
    } else if (item?.bed === "5B+D") {
      setBedValue("5 Bed+D");
    } else if (item?.bed === "6B") {
      setBedValue("6 Bed");
    } else if (item?.bed === "6B+D") {
      setBedValue("6 Bed+D");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div onClick={() => handleTotalClicked(item)}>
      <CardMedia>
        <ImageListItem className="d-flex justify-content-center align-items-center pt-1">
          <div className="cardmain_img">
            {item?.assignmentMedias &&
            item?.assignmentMedias?.length > 0 &&
            item.assignmentMedias.fileUrl !== "" ? (
              <img
                src={`${baseURL}/${item.assignmentMedias[0]?.fileUrl}`}
                alt={item?.projectName}
                loading="lazy"
                style={{ width: "100%", height: "100%", borderRadius: "8px" }}
              />
            ) : (
              <img
                src={require("../../../images/no-image.png")}
                alt={item?.projectName}
                loading="lazy"
                style={{ width: "100%", height: "100%", borderRadius: "8px" }}
              />
            )}

            <ImageListItemBar
              className="p-0 listwidth"
              style={{ top: 0 }}
              actionIcon={
                <CardContent
                  className="p-0"
                  sx={{
                    color: " rgba(255, 255, 255, 0.87)",
                    height: 300,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      bottom: 5,
                      left: 0,
                      fontFamily: "Montserrat",
                    }}
                  >
                    <div className="Image_Overlaysetting">
                      <div className="d-flex justify-content-center align-items-center">
                        <IoBed style={{ fontSize: "14px", color: "#ffff" }} />
                        <p className="card_abitem m-0">
                          {item?.bed ? `${bedValue}` : "Bed"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <BathtubIcon
                          sx={{ fontSize: "14px", color: "#ffff" }}
                        />
                        <p className="card_abitem m-0">
                          {item?.bath ? `${item?.bath} Bath` : "Bath"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <FaVectorSquare
                          style={{ fontSize: "14px", color: "#ffff" }}
                        />
                        <p className="card_abitem m-0">
                          {item?.interiorSize
                            ? `${item?.interiorSize?.toLocaleString(
                                "en-US"
                              )} Sqft`
                            : "Sqft"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <FaParking
                          style={{ fontSize: "14px", color: "#ffff" }}
                        />
                        <p className="card_abitem m-0">
                          {item?.parking ? item?.parking : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              }
            />

            <div
              style={{
                position: "absolute",
                top: 3,
                right: 6,
              }}
            >
              <button className="btn btndecor">ID: {item.id}</button>
            </div>

            <div
              style={{
                position: "absolute",
                top: 3,
                left: 6,
              }}
            >
              {item?.dailyUpdate && (
                <>
                  <button className="dealdecor">{item?.dailyUpdate}</button>
                </>
              )}
              {item?.assignmentPkgs?.some(
                (pkg) =>
                  pkg.category === "Tags" &&
                  !pkg.isExpired &&
                  pkg.isPaymentReceived
              ) && (
                <button
                  className={`dealdecor ${item?.dailyUpdate ? "ms-1" : ""}`}
                >
                  Hot Deals
                </button>
              )}
            </div>
          </div>
        </ImageListItem>
      </CardMedia>

      <CardContent
        className={`${
          listingDateHide ? "Listing_mainText" : ""
        }  maindataxx font`}
        style={{ padding: "5px 6px" }}
      >
        <div className="typeandDate_card">
          <div>
            {" "}
            {item?.type && item?.type !== "NULL" && (
              <>
                <button className="type_btn">{item.type}</button>
              </>
            )}
          </div>

          <div className="d-flex flex-column aligncenter w-auto">
            <div>
              {" "}
              <p className={`m-0 cardMain_date ${item?.type ? "ms-2" : "m-0"}`}>
                <strong className="card_posted mb_postdate">Posted:</strong>{" "}
                <span className="cardpara2 text-black">
                  {" "}
                  <strong>{formatDate(item?.created)}</strong>
                </span>
              </p>
            </div>
            <div>
              <p
                className={`${
                  listingDateHide ? "HideUserCard_MblDate" : ""
                } cardMain_date mb_closehide m-0`}
              >
                <strong className="text-danger">Closing:</strong>
                <span>
                  {" "}
                  <strong className="dated text-black">
                    {formatClosingDate(item?.occupanycDate)}
                  </strong>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="row wasandnow_price">
          {item?.assignmentPrices?.length > 0 ? (
            <div className="now_main">
              {" "}
              <p className="card_price2 was_mb">
                {/* <span className="Was_hide">Was</span>{" "} */}
                <span className="card_price2 cutwas_price">
                  <> ${item?.assignmentPrice?.toLocaleString("en-US")}</>
                </span>
                <span className="waspricee_sqft mx-2">{`$${WasSqftPrice.toLocaleString(
                  "en-US"
                )}/SQft`}</span>
              </p>
            </div>
          ) : null}

          <div className="now_main">
            <p className="card_now m-0">
              {/* <span className="Was_hide">Now</span> */}
              <span>
                {item.assignmentPrice && (
                  <> ${nowAssignmentPrice?.toLocaleString("en-US")}</>
                )}
              </span>
              <span className="nowpricee_sqft mx-2">{`$${NowSqftPrice.toLocaleString(
                "en-US"
              )}/SQft`}</span>
            </p>
          </div>
        </div>

        <div className="col-12">
          <p className="card_project m-0">
            <strong>{item.projectName && <>{item.projectName}</>}</strong>
          </p>
        </div>
        <div className="row">
          <div className="col-12 giveMeEllipsis1">
            {/* {item.developer && (
              <>
                <span className="cname">{item?.developer.slice(0,30)}</span>
              </>
            )} */}
            {item?.address && (
              <>
                {" "}
                <span className="iconfiller cname">
                  {item?.city
                    ? item?.address.substring(0, 17) +
                      (item?.address.length > 17 ? "..." : "")
                    : item?.address}
                </span>{" "}
                {item?.city && (
                  <>
                    <span
                      className="iconfiller cname"
                      style={{ color: "black" }}
                    >
                      |
                    </span>{" "}
                    <span className="iconfiller cname">{item?.city}</span>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <Typography
          className="mt-1 cardmain_highlight"
          variant="body2"
          color="text.secondary"
        >
          {!showListingOwnerName && item.highlight && (
            <>
              {item?.highlight?.length > 120
                ? `${item?.highlight?.slice(0, 120)} ...`
                : item?.highlight}
            </>
          )}
        </Typography>

        <Typography
          className="mt-1 cardmain_highlight"
          variant="body2"
          color="text.secondary"
        >
          {showListingOwnerName && item.highlight && (
            <>
              {item?.highlight?.length > 89
                ? `${item?.highlight?.slice(0, 90)} ...`
                : item?.highlight}
            </>
          )}
        </Typography>
        <Typography
          className="mt-1 cardmain_highlight"
          variant="body2"
          color="text.secondary"
        >
          {showListingOwnerName && item?.user ? (
            <>
              <span
                style={{ color: "#000", fontSize: "10px", fontWeight: 600 }}
              >
                Listed by:
              </span>
              <span
                style={{ color: "#02549E", fontSize: "13px", fontWeight: 600 }}
              >
                {" "}
                {`${item?.user?.firstName} ${item?.user?.lastName}`}{" "}
              </span>
            </>
          ) : null}
        </Typography>
      </CardContent>
    </div>
  );
}
