import React, { useState } from "react";
import { RatingFillIcon } from "../../../../SvgIcons/Icons";

const BarsAndResturants = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const BarsAndResturantData = data || [];
  const DatatoShow = showMore
    ? BarsAndResturantData
    : BarsAndResturantData.slice(0, 6);

  return (
    <>
      <div className="row mx-0">
        {DatatoShow?.map((item, i) => {
          return (
            <div
              className="col-md-6 col-12 px-2 mbl_Xpadding0 explore_propertyCard d-flex mt-4"
              key={i}
            >
              <div>
                <img src=   'https://media.gettyimages.com/id/1159992039/photo/cozy-restaurant-for-gathering-with-friends.jpg?s=612x612&w=gi&k=20&c=F7gXQJT6fSSIRNk3bxzqvhec4APiuDv8vZANOR44VmA=' loading="lazy" alt="ShopImg" />
              </div>
              <div className="ps-2 w-100">
                <h5 className="PropertyDetails_Mbl_f18">{item?.name}</h5>
                <h6 className="mb-1 PropertyDetails_Mbl_f14">{`Sea Food | Fast Food`}</h6>
                <h6>
                  <span>1100m</span>
                  {` | 14min walk`}
                </h6>
              </div>
              <div className="Explore_prop_rating">
                <RatingFillIcon /> <span>5</span>
              </div>
            </div>
          );
        })}
      </div>

      {BarsAndResturantData?.length > 6 && showMore ? (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      ) : null}
    </>
  );
};

export default BarsAndResturants;
