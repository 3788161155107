import React, { useState } from "react";
import { RatingFillIcon } from "../../../../SvgIcons/Icons";

const Schools = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const schoolData = data || [];
  const schoolDataToShow = showMore ? schoolData : schoolData.slice(0, 6);

  return (
    <>
      <div className="row mx-0">
        {schoolDataToShow?.map((item, i) => {
          return (
            <div
              className="col-md-6 col-12 mbl_Xpadding0 px-2 explore_propertyCard d-flex mt-4"
              key={i}
            >
              <div>
                <img
                  src="https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg"
                  loading="lazy"
                  alt="SchoolImg"
                />
              </div>
              <div className="ps-2 w-100">
                <h5 className="PropertyDetails_Mbl_f18">{item?.name}</h5>
                <h6 className="mb-1 PropertyDetails_Mbl_f14">{`Public | Elemantary | Toronto district School board`}</h6>
                <h6 className="PropertyDetails_Mbl_f16">
                  <span>1100m</span>
                  {` | 14 min walk | in boundary`}
                </h6>
              </div>
              <div className="Explore_prop_rating">
                <RatingFillIcon /> <span>5</span>
              </div>
            </div>
          );
        })}
      </div>

      {schoolData?.length > 6 && !showMore ? (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      ) : null}
    </>
  );
};

export default Schools;
