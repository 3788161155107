import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios"; // To make API requests
import DevelopmentToken from "../../../../MapBoxToken";

mapboxgl.accessToken = `${DevelopmentToken}`;

const PropertyMap = (props) => {
  const { setNearPlacesData, activeTab, data } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [markers, setMarkers] = useState([]);
  const locationMarker = useRef(null); // Reference for the custom center marker

  // Latitude and Longitude for the center of the map (default coordinates)
  const [center, setCenter] = useState({ lng: data?.longitude, lat: data?.latitude });

  useEffect(() => {
    if (map.current) return; // Initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.lng, center.lat], // Center of the map
      zoom: 12,
    });

    locationMarker.current = new mapboxgl.Marker({ color: "red" })
      .setLngLat([center.lng, center.lat])
      .addTo(map.current);

    // Set the z-index of the marker using CSS
    const markerElement = locationMarker.current.getElement();
    markerElement.style.zIndex = "1";
  }, [center]);

  // Function to fetch POIs based on the selected category
  const fetchPOIData = async (category) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/search/searchbox/v1/category/${activeTab}`,
        {
          params: {
            access_token: mapboxgl.accessToken,
            language: "en",
            limit: 10, // Number of results
            proximity: `${center.lng},${center.lat}`, // Center point to search around
          },
        }
      );

      // Clear previous markers
      markers.forEach((marker) => marker.remove());
      const newMarkers = [];
      const nearPlaces = [];
      // Add new markers to the map based on the API response
      response.data.features.forEach((feature) => {
        const [lng, lat] = feature.geometry.coordinates;
        nearPlaces.push({
          name: feature.properties.name,
          address: feature.properties.address,
        });
        const marker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `<h3>${feature.properties.name}${feature.properties.address}</h3>`
            )
          ) // Display name in popup
          .addTo(map.current);
        newMarkers.push(marker);
      });
      setNearPlacesData(nearPlaces);
      setMarkers(newMarkers);
    } catch (error) {
      console.error("Error fetching POI data:", error);
    }
  };

  // Fetch POIs when the active tab changes
  useEffect(() => {
    fetchPOIData(activeTab);
  }, [activeTab]);

  return (
    <div
      ref={mapContainer}
      style={{
        width: "100%",
        height: "250px",
        borderRadius: "20px",
        marginTop: "20px",
      }}
    />
  );
};

export default PropertyMap;
