export const PropertyData = {
  Amenities: [
    'Gym',
    'Private Pool',
    'Kitchen',
    'Air Condition',
    'Balcony',
    'Ventilation',
    'BBQ',
    'Electricity',
    'Fire Place'
  ],
  IncludeInMaintanceFee: [
    'Water',
    'Heat',
    'Hydro',
    'Air Conditioning',
    'Common Element',
    'Building Insurance',
    'Parking'
  ],
  Highlights1:
    '**MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities. Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.',
  Highlights2:
    'Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.',
  Incentives: [
    'Programmable smart Thermostat',
    'Smart Electronic door lock',
    'Smart Light switches',
    'Humidity sensitivity quite fan',
    'USB receptacle in kitchen',
    'Video doorbell',
    'Garage door opener',
    'WiFi connectivity',
    'In primary ensuite'
  ],
  WhyBuyProperty: [
    {
      label: 'Reason',
      content:
        '**MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    },
    {
      label: 'Pros',
      content:
        '**MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    },
    {
      label: 'Cons',
      content:
        '**MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    }
  ],
  FeatureAndFininishes: {
    title: 'COMFORTABLY ELEVATED',
    description:
      'Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.iews.*MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
  },
  FAQs: [
    {
      question: 'Why should a realtor choose Real Estate Leads?',
      answer:
        'The Treadwell’s residences evoke casual luxury, creating a warm and inviting atmosphere. Bathed in natural light from oversized windows, many units offer multiple exposures and unobstructed skyline views.*MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    },
    {
      question: 'Why should a realtor choose Real Estate Leads?',
      answer:
        'The Treadwell’s residences evoke casual luxury, creating a warm and inviting atmosphere. Bathed in natural light from oversized windows, many units offer multiple exposures and unobstructed skyline views.*MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    },
    {
      question: 'Why should a realtor choose Real Estate Leads?',
      answer:
        'The Treadwell’s residences evoke casual luxury, creating a warm and inviting atmosphere. Bathed in natural light from oversized windows, many units offer multiple exposures and unobstructed skyline views.*MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    },
    {
      question: 'Why should a realtor choose Real Estate Leads?',
      answer:
        'The Treadwell’s residences evoke casual luxury, creating a warm and inviting atmosphere. Bathed in natural light from oversized windows, many units offer multiple exposures and unobstructed skyline views.*MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary.'
    }
  ],
  AvailableHome: [
    {
      FloorPlans: [
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        }
      ],
      Units: [
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        }
      ],
      MoveIns: [
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        }
      ],
      Sold: [
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        },
        {
          projectName: 'The RockWell',
          projectNumber: '# 10A',
          floorPlanImg:
            'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
          price: 345678,
          bed: 2,
          bath: 3,
          sqft: 143
        }
      ]
    }
  ],
  popularSearches: [
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West'
  ],
  nearbyCities: [
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West',
    'Condo for sale in king West'
  ],
  propertyDistribution: [
    { name: 'Detached', value: 10, color: 'red' },
    { name: 'Semi-Detached', value: 20, color: 'orange' },
    { name: 'Townhouse', value: 20, color: 'purple' },
    { name: 'Condo Apt', value: 20, color: 'yellow' },
    { name: 'Condo Apt', value: 20, color: 'blue' },
    { name: 'Condo Apt', value: 10, color: 'green' }
  ],
  statics: [
    {
      label: 'Household',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 20, color: 'blue' },
        { name: 'Condo Apt', value: 10, color: 'green' }
      ]
    },
    {
      label: 'Age',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 25, color: 'blue' },
        { name: 'Condo Apt', value: 5, color: 'green' }
      ]
    },
    {
      label: 'Education',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 20, color: 'blue' },
        { name: 'Condo Apt', value: 10, color: 'green' }
      ]
    },
    {
      label: 'Ethnicity',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 20, color: 'blue' },
        { name: 'Condo Apt', value: 10, color: 'green' }
      ]
    },
    {
      label: 'Language',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 20, color: 'blue' },
        { name: 'Condo Apt', value: 10, color: 'green' }
      ]
    },
    {
      label: 'Construction',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 20, color: 'blue' },
        { name: 'Condo Apt', value: 10, color: 'green' }
      ]
    },
    {
      label: 'Peoples',
      content: [
        { name: 'Detached', value: 10, color: 'red' },
        { name: 'Semi-Detached', value: 20, color: 'orange' },
        { name: 'Townhouse', value: 20, color: 'purple' },
        { name: 'Condo Apt', value: 20, color: 'yellow' },
        { name: 'Condo Apt', value: 20, color: 'blue' },
        { name: 'Condo Apt', value: 10, color: 'green' }
      ]
    }
  ],
  demographics: [
    { name: '0 - 14 years old', value: 10, color: 'red' },
    { name: '15 - 19 years old', value: 40, color: 'orange' },
    { name: '20 - 34 years old', value: 10, color: 'purple' },
    { name: '35 -49 years old', value: 15, color: 'blue' },
    { name: '50 - 64 years old', value: 15, color: 'yellow' },
    { name: '65+ years old', value: 10, color: 'green' }
  ],
  similarListing: [
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    }
  ],
  forSale: [
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    }
  ],
  featuredListing: [
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    },
    {
      slug: 'Fifth-Avenue-Homes-TownHouse-Richmond-Hill-293379',
      lotWidth: 24,
      agent: false,
      commission: null,
      brokerageName: null,
      highlight:
        'High Demand Yonge/King Neighbourhood Over 2300 Sqft Of Modern Upgraded Living Space 10` Ceiling On Main, 9` On Ground',
      propStatus: 'Available',
      type: 'TownHouse',
      style: null,
      occupanycDate: '2024-12-01T00:00:00',
      interiorSize: 2366,
      exteriorSize: null,
      bed: '3B',
      bath: 4.0,
      parking: 1,
      locker: 0,
      parkingType: null,
      garage: 'Single',
      exposure: 'N',
      floorRange: null,
      maintainenceFee: null,
      levyFee: 0.0,
      leaseDuringOccupancy: false,
      colorselectionchosen: false,
      developer: 'Fifth Ave Homes',
      projectName: 'Fifth Avenue Homes',
      address: 'King Road/McCachen Street',
      latitude: 43.9429433,
      longitude: -79.4693451,
      country: 'Canada',
      city: 'Richmond Hill',
      region: 'Richmond Hill',
      postalcode: 'L4E 2X5',
      mainIntersection: null,
      upgradeDepositPaid: null,
      upgradeBalance: null,
      originalPrice: 1610531.0,
      depositToBuilder: 200000.0,
      assignmentPrice: 1820800.0,
      occupancyDue: 0.0,
      totalToSeller: null,
      other: null,
      status: 'Active',
      dailyUpdate: 'Just Reached',
      dailyUpdateDate: '2024-05-14T11:43:50.307',
      isAssignmentSoldData: false,
      source: 'ToAssign',
      sourceName: null,
      sourceNumber: null,
      sourceEmail: null,
      sourceId: null,
      sourceUrl: 'https://toassign.com/assignment/589',
      listType: 'Assignment',
      verificationDate: '2024-05-07T19:35:30.083',
      verify: true,
      favourites: [],
      assignmentPrices: [],
      assignmentAmenitys: [],
      assignmentMedias: [
        {
          fileDirectoryPath: null,
          assignmentId: 846,
          primaryImage: false,
          mediaCategory: '',
          verify: true,
          id: 2102,
          fileName: '00450523-MFTS.jpg',
          extension: '.jpg',
          fileUrl: 'Attachments\\Assignment/00450523-MFTS.jpg',
          userId: 20,
          created: '2024-05-14T11:43:50.427',
          user: null,
          base64: null,
          fileContent: null,
          fileId: 0
        }
      ]
    }
  ],
  PriceHistory: [
    {
      soldFor: 685932,
      PropertyImg:
        'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
      listedFor: 345678,
      listedDate: '7 May 2024',
      yearAgo: '2 month ago',
      daysOnMarket: '80 days'
    },
    {
      soldFor: 685932,
      PropertyImg:
        'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
      listedFor: 345678,
      listedDate: '7 May 2024',
      yearAgo: '2 month ago',
      daysOnMarket: '80 days'
    },
    {
      soldFor: 685932,
      PropertyImg:
        'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
      listedFor: 345678,
      listedDate: '7 May 2024',
      yearAgo: '2 month ago',
      daysOnMarket: '80 days'
    },
    {
      soldFor: 685932,
      PropertyImg:
        'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
      listedFor: 345678,
      listedDate: '7 May 2024',
      yearAgo: '2 month ago',
      daysOnMarket: '80 days'
    },
    {
      soldFor: 685932,
      PropertyImg:
        'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
      listedFor: 345678,
      listedDate: '7 May 2024',
      yearAgo: '2 month ago',
      daysOnMarket: '80 days'
    },
    {
      soldFor: 685932,
      PropertyImg:
        'https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg',
      listedFor: 345678,
      listedDate: '7 May 2024',
      yearAgo: '2 month ago',
      daysOnMarket: '80 days'
    }
  ],
  BuilderPriceHistory: [
    {
      date: '7 May 2024',
      Status: 'Unit made Not Available by Corcoran Sunshine Marketing Group',
    },
    {
      date: '7 May 2024',
      Status: 'Unit made Not Available by Corcoran Sunshine Marketing Group',
    },
    {
      date: '7 May 2024',
      Status: 'Unit made Not Available by Corcoran Sunshine Marketing Group',
    },
    {
      date: '7 May 2024',
      Status: 'Unit made Not Available by Corcoran Sunshine Marketing Group',
    },
    {
      date: '7 May 2024',
      Status: 'Unit made Not Available by Corcoran Sunshine Marketing Group',
    },
    {
      date: '7 May 2024',
      Status: 'Unit made Not Available by Corcoran Sunshine Marketing Group',
    },
  ],
  taxHistory: [
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    },
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    },
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    },
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    },
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    },
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    },
    {
      year: '2016',
      taxPaid: 9619,
      taxAssessment: 789456,
      land: 89456,
      improvement: 189456
    }
  ],
  rooms: [
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
    {
      lavel: 'Flat',
      name: 'Living',
      size: '18.6 * 12.0',
      features: 'Separate Rm, Broadloom, Bay Window'
    },
  ],
  MLSData: [
    {
      ListingData: {
        'Property Type': 'Residential',
        'Property Sub Type': 'Single Family Residence',
        Restrictions: 'Deed Restrictions',
        'Year Built': 1995,
        'Additional Parcels': 'No',
        'Direction Faces': 'NE',
        Directions: [
          'From westbound RM 2222, turn left onto N FM 620',
          'Turn right onto Hudson Bend Rd',
          'Turn left onto Rocky Ridge Rd',
          'Turn left onto Serrano Trail',
          'Take the second left onto Serrano Trail',
          'The home will be on the right'
        ],
        Disclosures: ['Other Disclosures', 'Seller Disclosure'],
        'Estimated Taxes': 9619.0,
        'ETJ Extra Territorial Jurdn': 'No',
        'FEMA Flood Plain': 'No',
        Fencing: 'Wire',
        'Green Energy Efficient': 'None',
        'Green Sustainability': 'None',
        'Guest Accommodation Description': 'None',
        Intermediary: 'Yes',
        Levels: 'One',
        'Living Area': 1925.0,
        'Other Structures': ['RV/Boat Storage', 'See Remarks'],
        'Special Listing Conditions': 'Standard',
        'Special Features': 'None'
      },
      interiorDetails: {
        'Interior Amenities': [
          'Breakfast Bar',
          'Ceiling Fan(s)',
          'Ceiling-Vaulted',
          'Counter-Quartz',
          'Electric Dryer Hookup',
          'In-Law Floorplan',
          'No Interior Steps',
          'Open Floorplan',
          'Primary Bedroom on Main',
          'Recessed Lighting',
          'Storage',
          'Walk-In Closet(s)',
          'Washer Hookup'
        ],
        'Laundry Location': ['Inside', 'Laundry Room'],
        View: ['Trees/Woods'],
        Flooring: ['Tile', 'Wood'],
        'Num Dining': 1,
        'Num Living': 1,
        'Accessibility Features': 'None',
        Appliances: [
          'Dishwasher',
          'Disposal',
          'Electric Range',
          'Exhaust Fan',
          'Microwave',
          'Stainless Steel Appliance(s)',
          'Water Heater-Electric'
        ],
        'Main Level Bedrooms': 4,
        'Property Condition': 'Resale',
        'Security Features': ['Security System', 'Smoke Detector(s)'],
        'Window Features': ['Screens']
      },
      bedBathDetails: {
        'Full Bathrooms': 1,
        Bathrooms: 1,
        Bedrooms: 1
      },
      buildingInfo: {
        'Construction Materials': ['Masonry – All Sides', 'Stone'],
        'New Construction': 'No'
      },
      garageInfo: {
        'Parking Features': ['Carport', 'Covered'],
        'Total Parking Spaces': '2.00'
      },
      associationAmenities: {
        'Community Features': [
          'Lake',
          'Park',
          'Lake',
          'Picnic Area',
          'Playground',
          'Walk/Bike/Hike/Jog Trail(s, See Remarks'
        ],
        Association: 'No'
      },
      exteriorfeatures: {
        'Exterior Features': ['Exterior Steps', 'Gutters Full', 'Private Yard'],
        Roof: 'Composition',
        Foundation: 'Slab',
        'Patio And Porch Features': [
          'Covered',
          'Deck',
          'Front Porch',
          'See Remarks'
        ],
        'Pool Features': 'None',
        'Private Pool': 'No',
        'Water Body Name': 'Lake Travis',
        'Waterfront Features': 'None',
        Waterfront: 'No'
      },
      utilitiesDetails: {
        Sewer: 'Septic Tank',
        Utilities: [
          'Cable Available',
          'Electricity Available',
          'Internet-Cable',
          'Water Available'
        ],
        Heating: ['Central', 'Electric'],
        'Water Source': 'Municipal Utility District (MUD)',
        Cooling: ['Attic Fan', 'Central Air', 'Electric']
      },
      lotDetails: {
        'Lot Features': [
          'Back Yard',
          'Front Yard',
          'Interior Lot',
          'Native Plants',
          'Sprinkler - In-ground',
          'Many Trees',
          'Trees-Medium (20 Ft - 40 Ft)',
          'Trees-Small (Under 20 Ft)'
        ],
        'Parcel Number': '01626304200000',
        'Lot Size Acres': 0.6119,
        'Lot Size Sq Ft': 26654.36,
        'Subdivision Name': 'Cook Gerald T Sub Sec 2 Amd'
      },
      TaxInfo: {
        'Tax Filled Sqft Total': 1813,
        'Tax Lot': '9A',
        'Tax Rate': 1.77,
        'Tax Year': 2022,
        'Tax Annual Amount': 9619.29,
        'Tax Exemptions': 'None'
      },
      compensationDetails: {
        'Buyer Agency Compensation': 3.0,
        'Sub-Agency Compensation': 0.0,
        'Buyers Agency Compensation Disclaimer':
          'The listing broker’s offer of compensation is made only to participants of the MLS where the listing is filed.'
      }
    }
  ],
  HomeDetails: {
    Views: ['Creek or Stream', 'Woods'],
    HomeDesign: [
      'Built in 2003',
      'Estate',
      'Slab Foundation',
      'Metal Roof',
      'Masonry Siding',
      'Stone Siding'
    ],
    interiorSpace: [
      '5,807 Sq Ft Home',
      '1-Story Property',
      'Open Floorplan',
      'Sound System',
      'Wired For Sound',
      'Wired For Data',
      'Bookcases',
      'Built-In Features',
      'Fireplace in Living Room',
      'Stone Fireplace',
      'Insulated Windows',
      'Window Treatments',
      'Dining Area',
      'Workshop'
    ],
    flooring: ['Wood', 'Concrete', 'Tile'],
    bedAndBathDetails: [
      '6 Main Level Bedrooms',
      'Two Primary Bedrooms',
      'His and Hers Closets',
      'Two Primary Bathrooms',
      '4 Full Bathrooms',
      'Double Vanity'
    ],
    laundryDetails: [
      'Laundry Room',
      'Laundry in Multiple Locations',
      'Washer and Dryer'
    ],
    securityDetails: ['Home Security System', 'Security Lights'],
    parkingDetails: [
      '2 Parking Spaces',
      'Carport',
      'Private Parking',
      'Gravel Driveway',
      'Additional Parking'
    ],
    outdoorFeatures: ['Deck', 'Patio', 'Rear Porch', 'Rain Gutters'],
    kitchenDetails: [
      'Built-In Electric Oven',
      'Cooktop',
      'Range Hood',
      'Dishwasher',
      'Wine Refrigerator',
      'Kitchen Island',
      'Granite Countertops',
      'Disposal'
    ],
    schoolDetails: [
      'Lake Travis Elementary School',
      'Hudson Bend Middle School',
      'Lake Travis High School'
    ],
    utilitiesDetails: [
      'Central Heating and Cooling System',
      'Municipal Utilities District Water',
      'Water Purifier',
      'Septic Tank'
    ],
    additionalFeatureDetails: [
      'No Interior Steps',
      'Sustainability products and practices used to construct the property include Conserving Methods',
      'Guest House'
    ],
    lotWidthDetails: [
      '7 Acre Lot',
      'Home fronts a creek',
      'Home Entrance Faces East',
      'Stone Wall',
      'Partially Fenced Property',
      'Wood Fence',
      'Native Plants',
      'Corner Lot',
      'Pie Shaped Lot',
      'Partial Sprinkler System',
      'Mature Trees',
      'Many Trees',
      'Back Yard Fenced',
      'Garden',
      'The community has rules related to exclusive easements'
    ],
    communityDetails: [
      'No Interior Steps',
      'Sustainability products and practices used to construct the property include Conserving Methods',
      'Guest House'
    ],
    financialDetails: [
      '$8,832 Total Annual Tax in 2022',
      'Homeowner Tax Exemptions: See Remarks',
      'Legal Lot and Block 40 / 1',
      'Assessor Parcel Number 01626001110000',
      '2% Total Tax Rate'
    ]
  },
}
