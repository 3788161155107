import React, { useState } from "react";
import "./Signup.css";
import "../../Components.css";
import UserSignUp from "./Subcomponents/UserSignUp";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

export default function Signup() {
  const jwtCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("jwt_authorization="));
  const isLogedIn = localStorage.getItem("anonymous");
  const isUserLoggedIn = jwtCookie && isLogedIn === "1";

  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/");
    }
  }, [isUserLoggedIn, navigate]);

  const [isBuyer, setIsBuyer] = useState(true);
  const [userType, setUserType] = useState("Buyer");

  const handleBuyerClick = () => {
    setUserType("Buyer");
    setIsBuyer(true);
  };

  const handleSellerClick = () => {
    setUserType("Seller");
    setIsBuyer(false);
  };

  return (
    <div>
      <HelmetLayout pageName={"signup"} canonicalLink={"Signup"} />
      <div className="container centered signup_topPadding  aligning">
        <div className="imagecentered">
          <NavLink to="/">
            <img
              className="imgclass1"
              src={require("../../images/New__LOGO.png")}
              alt="AssignmentSold logo"
              loading="lazy"
            />
          </NavLink>
        </div>
        <p className="font contactheading cacc1">
          Register to Assignment<span className="textgreen">Sold</span>{" "}
        </p>
        <p className="font contactheading cacc ">Create Account</p>

        <div className=" p-0 ">
          {" "}
          <button
            className={isBuyer ? "Buyerbutton active" : "Sellerbutton"}
            onClick={() => {
              handleBuyerClick();
            }}
          >
            I am Buyer
          </button>{" "}
          <button
            className={isBuyer ? " Sellerbutton active" : "Buyerbutton"}
            onClick={() => {
              handleSellerClick();
            }}
          >
            I am Seller
          </button>
        </div>

        <div>
          <UserSignUp userType={userType} />
        </div>
      </div>
    </div>
  );
}
