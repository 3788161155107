import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "./PaymentDetails";


const stripePromise = loadStripe(
  "pk_test_51P1xrXAuTT10Ql8lrsvtymFHwTIh2VCDjqZVCwAoOhhANs9d3n3ImAsc1LR54clpjo5dJORp4wfup0ri4xwdKBcV00GCjkwWbH"
);

const PayModal = ({
  assignmentId,
  setShowPayModal,
  selectedPackages,
  setSelectedPackages,
  showPayModal,
  handleClosePayModal,
  handlePayLater,
  showPayLaterBtn,
  setData,
  data
}) => {
  
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      show={showPayModal}
      centered
      onHide={handleClosePayModal}
      className="BumpAdd_modal"
      backdrop="static"
    >
      <Modal.Body className="Bump_modal_body" >
        <Elements stripe={stripePromise} >
          <PaymentDetails
            showPayLaterBtn={showPayLaterBtn}
            setShowPayModal={setShowPayModal}
            selectedPackages={selectedPackages}
            setSelectedPackages={setSelectedPackages}
            handlePayLater={handlePayLater}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            assignmentId={assignmentId}
            data={data}
            setData={setData}
          />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};

export default PayModal;
