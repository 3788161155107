import React, { useState } from "react";
import { RatingFillIcon } from "../../../../SvgIcons/Icons";

const Hospitals = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const HospitalData = data || [];
  const HospitalDataToShow = showMore ? HospitalData : HospitalData.slice(0, 6);

  return (
    <>
      <div className="row mx-0">
        {HospitalDataToShow?.map((item, i) => {
          return (
            <div
              className="col-md-6 col-12 explore_propertyCard d-flex mbl_Xpadding0 px-2 mt-4"
              key={i}
            >
              <div>
                <img
                  src="https://config.assignmentsold.ca/Attachments/Assignment/00438637-MFTS.jpg"
                  loading="lazy"
                  alt="HospitalImg"
                />
              </div>
              <div className="ps-2 w-100">
                <h5 className="PropertyDetails_Mbl_f18">{item?.name}</h5>
                <h6 className="mb-1 PropertyDetails_Mbl_f16">
                  {/* {item?.description?.length < 108
                    ? item?.description
                    : `${item?.description?.slice(0, 108)}...`} */}
                  testing Hospital ith map to show the nearby hospital according
                  to...
                </h6>
              </div>
              <div className="Explore_prop_rating">
                <RatingFillIcon /> <span>5</span>
              </div>
            </div>
          );
        })}
      </div>

      {HospitalData?.length > 6 && !showMore ? (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      ) : null}
    </>
  );
};

export default Hospitals;
